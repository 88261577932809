import React from "react";
import { Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  rlcHero,
  rlcWhiteGlove,
  rlcBoothData,
  showRotarySection,
} from "../../data/events/rlc-data";
import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
import heroBg from "../../images/hero-images/nightclub-bar-event-hero.png";
import RotarySection from "../../components/RotarySection";
import EventForm from "../../components/EventsComps/EventForm";
import BoothCTA from "../../components/EventsComps/BoothCTA";
import Heading from "../../components-v2/Base/HeadingBuilder";

const whiteGloveImport = "event-white-glove.png";
const { Title } = Typography;

const customHero = () => {
  return (
    <>
      <Heading
        level={1}
        injectionType={4}
        className="hero-b__title text-center"
        style={{ fontSize: 42 }}
      >
        Join SpotOn at the Restaurant Leadership Conference
        <span className="blue_dot">.</span>
        <br />
        <p
          style={{
            color: "#1769FF",
            fontSize: "32px",
            marginBottom: "24px",
            lineHeight: 1.5,
          }}
        >
          Phoenix, Arizona | December 6 - 9, 2021
        </p>
        <p
          className="text-center"
          style={{
            color: "#748093 !important",
            fontSize: 20,
            marginTop: "24px",
            fontWeight: 400,
            lineHeight: 1.5,
          }}
        >
          Join SpotOn at the Restaurant Leadership Conference in Phoenix,
          Arizona to see why our integrated technology is a better way to run
          your restaurant. Or click below to get a demonstration.
        </p>
      </Heading>
    </>
  );
};

const rlc = () => {
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="Restaurant Leadership Conference | SpotOn | Restaurant software and payment technology"
        description="Join SpotOn at the Restaurant Leadership Conference December 6 - 9, 2021, to see why SpotOn is a better way to run your restaurant."
        image={`https://spoton-website-staging.netlify.app/${heroBg}`}
      />
      <BHero
        sectionData={rlcHero}
        heroBg="iaapa-hero.png"
        clearBg
        customHeroContent={customHero()}
      />
      <div style={{ marginTop: "64px" }} />
      <WhiteGlove sectionData={rlcWhiteGlove} whiteGloveBg={whiteGloveImport} />
      <BoothCTA sectionData={rlcBoothData} />
      <RotarySection sectionData={showRotarySection} />
      <EventForm
        id="demo"
        leadType="tradeshow"
        formId="3802e416-0f35-43ee-936a-1b1cc7037886"
        thankYouPath="/events/thank-you"
      />
      {/*      <Articles
        sectionData={rlcArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default rlc;
